<template>
  <div class="app-box">
    <x-scroll-view ref="scrollView" :bottom="0.66" @scrollBarPos="scrollBarPos">
      <x-form-box>
        <x-form-date label="招待时间"  @change="entertainDateChange" :defaultDate="xhrData.entertainDate"></x-form-date>
        <x-form-select label="招待类型" :slots="EntertainTypeEnum" @change="entertainTypeChange" :bindValue="xhrData.entertainType"></x-form-select>
        <x-form-textarea label="招待对象公司及岗位" v-model="xhrData.entertainObject"></x-form-textarea>
        <x-form-input label="招待和陪同人数" placeholder="" v-model="xhrData.personNum"></x-form-input>
        <x-form-input label="招待金额(元)" placeholder="" v-model="xhrData.amount"></x-form-input>
        <x-form-input label="费用申请人" placeholder="" disable v-model="xhrData.name"></x-form-input>
      </x-form-box>
    </x-scroll-view>
    <x-footer-box :type="type"  @saveEvent="saveClick" @delEvent="delClick"></x-footer-box>
  </div>
</template>

<script>

import formBox from "@/components/formControl/formBox";
import formInput from "@/components/formControl/formInput";
import formSelect from "@/components/formControl/formSelect";
import formPosition from "@/components/formControl/formPosition";
import formTextArea from "@/components/formControl/formTextArea";
import formDrugsList from "@/components/formControl/formDrugsList";
import formDeviceList from "@/components/formControl/formDeviceList";
import formDate from "@/components/formControl/formDate";
import formTag from "@/components/formControl/formTag";
import formImages from "@/components/formControl/formImages";
import footerBox from "@/components/footerBox";
import scrollView from "@/components/scrollView";
import map from "@/components/visitRecord/map";
import { cachePage } from "@/script/mixins/cachePage";
import { getNowTime } from "@/script/tools";
import { getCustomer } from "@/api/customerCenter";
import { getEntertain,addEntertain, delEntertain, editEntertain } from "@/api/entertainList";
import { qqMapGeocoder } from "@/api/base";
import { ajaxBack } from "@/script/mixins/ajaxBack";
import { MessageBox } from "mint-ui";

export default {
  name: "entertainListEdit",
  data () {
    return {
      scrollBarTop: 0,
      isDisabledBtn: false,
      isStartVisit: true,
      isVisit: true,
      type: this.$route.params.type,
      //type:"1",
      personNum: null,
      amount: null,
      staffName: null,
      latLng: null,
      staffId: null,
      entertainDate:null,
      xhrData: {
        //customerId: this.$route.params.id,
        customerId: 1,
        entertainDate: null,
        entertainType: null,
        entertainObject: null,
        personNum: null,
        amount: null,
        staffName: null,
        staffId: 1
      },
      customerData: null,
      // shopOpenEnum: [{
      //   flex: 1,
      //   values: [{
      //     code: 1,
      //     name: "是"
      //   }, {
      //     code: 0,
      //     name: "否"
      //   }],
      //   textAlign: "center",
      //   defaultIndex: 0
      // }],
      EntertainTypeEnum: [{
        flex: 1,
        values: [],
        textAlign: "center",
        defaultIndex: 0
      }],
      VisitOtherChanceEnum: []
    };
  },
  mixins: [cachePage, ajaxBack],
  created () {
    document.title = "";
    document.title = "电子招待清单详情";
    console.log("created");
    this.setEnum();
  },
  methods: {
    visitBtn () {
      if (this.isVisit) {
        if (this.scopeIs) {
          this.cardEvent();
        } else {
          MessageBox({
            title: null,
            message: "当前不在打卡范围内，确定打卡?",
            showCancelButton: true
          }).then(action => {
            action == "confirm" && this.cardEvent();
          });
        }
      }
    },
    cardEvent () {
      qqMapGeocoder({
        latitude: this.latLng[0],
        longitude: this.latLng[1]
      }).then(xhr => {
        if (this.isStartVisit) {
          this.xhrData.beginLatitude = this.latLng[0];
          this.xhrData.beginLongitude = this.latLng[1];
          this.xhrData.beginAddress = xhr.data.result.address;
          this.xhrData.beginTime = getNowTime();
          this.xhrData.scopeIsOkBegin = this.scopeIs;
          addEntertain(this.xhrData).then(xhr => {
            this.xhrData.id = xhr.data;
          });
        } else {
          this.xhrData.endLatitude = this.latLng[0];
          this.xhrData.endLongitude = this.latLng[1];
          this.xhrData.endAddress = xhr.data.result.address;
          this.xhrData.endTime = getNowTime();
          this.xhrData.scopeIsOkEnd = this.scopeIs;
          editEntertain(this.xhrData);
        }
        this.setIsVisit();
      });
    },
    mapChange (item) {
      this.latLng = item.latLng;
      this.scopeIs = item.scopeIs;
    },
    visitImgChange (item) {
      this.xhrData.visitImgList = [];
      item.map(val => {
        this.xhrData.visitImgList.push(val.id);
      });
    },
    businessChanceImgChange (item) {
      this.xhrData.businessChanceImgList = [];
      item.map(val => {
        this.xhrData.businessChanceImgList.push(val.id);
      });
    },
    scrollBarPos (scrollBarTop) {
      this.scrollBarTop = scrollBarTop;
    },
    deviceChange (item) {
      this.$refs.scrollView.setScrollTopPos(this.scrollBarTop);
      this.xhrData.facilityDtoList = item;
    },
    drugsChange (item) {
      this.$refs.scrollView.setScrollTopPos(this.scrollBarTop);
      this.xhrData.medicineIdList = [];
      item.map(val => {
        this.xhrData.medicineIdList.push(val.id);
      });
    },
    // shopOpenChange (item) {
    //   this.xhrData.shopOpen = item.code;
    // },
    entertainTypeChange (item) {
      this.xhrData.entertainType = item.code;
    },
    visitOtherChanceChange (item) {
      this.xhrData.otherChances = [];
      item.map(val => {
        this.xhrData.otherChances.push(val.code);
      });
    },
    setEnum () {
      const configJson = JSON.parse(sessionStorage.getItem("configJson"));
      Object.keys(configJson.EntertainTypeEnum).forEach(key => {
        this.EntertainTypeEnum[0].values.push({
          code: key,
          name: configJson.EntertainTypeEnum[key]
        });
      });
      // Object.keys(configJson.VisitOtherChanceEnum).forEach(key => {
      //   this.VisitOtherChanceEnum.push({
      //     code: key,
      //     name: configJson.VisitOtherChanceEnum[key],
      //     check: false
      //   });
      // });
      // this.type == "new" && this.getCustomerData(this.$route.params.id);
      if(this.$route.params.id && this.$route.params.id > 0){
        this.getData();
      }else{
        this.xhrData.name = JSON.parse(sessionStorage.getItem("token")).name;
        this.xhrData.saleId = JSON.parse(sessionStorage.getItem("token")).id;
      }
    },
    getData () {
      console.log(this.$route.params.id);
      getEntertain({
        id: this.$route.params.id
      }).then(xhr => {
        this.xhrData = xhr.data;
        console.log("xhrData:" + JSON.stringify(this.xhrData));
        // this.xhrData.scopeIsOkBegin = parseInt(this.xhrData.scopeIsOkBegin);
        // this.xhrData.scopeIsOkEnd = parseInt(this.xhrData.scopeIsOkEnd);
        // this.name = this.xhrData.staffVo.name;
        // this.telephone = this.xhrData.staffVo.telephone;
        // this.positon = this.xhrData.staffVo.positon;
        //this.staffId = this.xhrData.staffId;
        //this.entertainDate = this.xhrData.entertainDate;
        //this.getCustomerData(this.xhrData.customerId);
      });
    },
    getCustomerData (id) {
      getCustomer({
        customerId: id
      }).then(xhr => {
        this.customerData = xhr.data;
        this.setIsVisit();
      });
    },
    setIsVisit () {
      if (this.customerData.address && !this.xhrData.forgetEnd) {
        if (this.xhrData.beginAddress && this.xhrData.endAddress) {
          this.isVisit = false;
        } else {
          this.isVisit = !!this.xhrData.staffId;
        }
      } else {
        this.isVisit = false;
      }
    },
    namTagEvent () {
      this.$router.push({
        path: `/visitRecord/pick/${this.$route.params.id}`
      });
    },
    saveClick () {
      if (!this.isDisabledBtn) {
        if (!this.xhrData.id) {
          addEntertain(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, "保存成功！");
          });
        } else {
          editEntertain(this.xhrData).then(xhr => {
            this.ajaxBack(xhr, this.type == "new" ? "保存成功！" : "修改成功！");
          });
        }
      }
    },
    delClick () {
      delEntertain({
        id: this.$route.params.id
      }).then(xhr => {
        this.ajaxBack(xhr, "删除成功！", -2);
      });
    },
    entertainDateChange (item) {
      this.xhrData.entertainDate = item;
    }
  },
  watch: {
    xhrData: {
      handler (newVal) {
        //this.isDisabledBtn = !newVal.staffId || !this.xhrData.beginAddress;
        this.isDisabledBtn = false;
        this.isStartVisit = !(newVal.beginLatitude && newVal.beginLongitude);
        this.isVisit = !!this.xhrData.staffId;
      },
      deep: true
    },
    $route () {
      const searchValue = this.$store.getters.getSearchValue;
      if (searchValue) {
        this.name = searchValue.name;
        this.xhrData.staffName = searchValue.name;
        this.positon = searchValue.positon || searchValue.otherPositon;
        this.telephone = searchValue.telephone || searchValue.fixedLineTelephone;
        this.xhrData.staffId = searchValue.id;
        this.$store.commit("setSearchValue", null);
      }
    }
  },
  components: {
    [formBox.name]: formBox,
    [formInput.name]: formInput,
    [formSelect.name]: formSelect,
    [formPosition.name]: formPosition,
    [formTextArea.name]: formTextArea,
    [formDrugsList.name]: formDrugsList,
    [formDeviceList.name]: formDeviceList,
    [formTag.name]: formTag,
    [formImages.name]: formImages,
    [formDate.name]: formDate,
    [footerBox.name]: footerBox,
    [scrollView.name]: scrollView,
    [map.name]: map
  }
};
</script>

<style scoped>
.title-dot {
  margin-left: 0.04rem;
  color: #f75959;
}
.client-box {
  margin-bottom: 0.1rem;
}
.client-title-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.client-title-box label {
  display: inline-table;
  padding: 0.02rem 0.06rem;
  border-radius: 0.02rem;
  background-color: rgba(43, 130, 247, 0.1);
  line-height: 1;
  font-size: 0.12rem;
  color: #2b82f7;
}
.client-title-box button {
  border: 0;
  font-size: 0.15rem;
  color: #ffffff;
  background-color: #2b82f7;
  box-shadow: 0 0.02rem 0.02rem 0 #d0e8ff;
  border-radius: 0.24rem;
  line-height: 1;
  padding: 0.08rem 0.16rem;
}
.client-title-box .client-no-data {
  background-color: #9fc7fc;
  box-shadow: 0 0.02rem 0.02rem 0 #d0e8ff;
}
.client-box p {
  line-height: 0.16rem;
  width: 2.7rem;
  font-size: 0.14rem;
  color: #737885;
  margin-top: 0.08rem;
}
.client-box .client-txt-no-data {
  color: #c6cad1;
}
.name-tag-icon {
  margin-left: 0.1rem;
  width: 0.15rem;
  height: 0.18rem;
  background: url('~@images/name_tag_icon.png') no-repeat center/0.15rem 0.18rem;
}
</style>
