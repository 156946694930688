import axios from "@/axios/axiosConfig";

export const queryList = (params) => {
  return axios({
    url: "/entertainList/queryList",
    method: "post",
    data: params
  });
};

export const addEntertain = (params) => {
  return axios({
    url: "/entertainList/add",
    method: "post",
    data: params
  });
};

export const getEntertain = (params) => {
  return axios({
    url: `/entertainList/get/${params.id}`,
    method: "get"
  });
};

export const delEntertain = (params) => {
  return axios({
    url: `/entertainList/del/${params.id}`,
    method: "get"
  });
};

export const editEntertain = (params) => {
  return axios({
    url: "/entertainList/update",
    method: "post",
    data: params
  });
};

export const unfinishedRecord = () => {
  return axios({
    url: "/entertainList/unfinishedRecord",
    method: "get"
  });
};

export const getPrintData = (params) => {
  return axios({
    url: `/entertainList/getPrintData/${params.id}`,
    method: "get",
    responseType:"blob"
  });
};
