<template>
  <div class="form-drugs-box">
    <div class="form-label-box">
      <label class="form-label">{{label}}</label>
      <i v-if="!disable" class="form-label-add" @click="addEvent"></i>
    </div>
    <ul v-if="xhrData.length>0" class="form-drugs-list">
      <li v-for="(item,index) in xhrData" :key="index" class="form-drugs-item">
        <p :class="{'form-drugs-disable':disable}">{{item.medicineName}}</p>
        <i v-if="!disable" @click="delEvent(item);"></i>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "x-form-drugs-list",
  data () {
    return {
      xhrData: []
    };
  },
  props: {
    label: {
      type: String,
      default: null
    },
    disable: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => []
    }
  },
  created () { },
  methods: {
    addEvent () {
      this.$router.push({
        path: "/searchDrugs/drugs/setSearchDrugsValue"
      });
    },
    delEvent (item) {
      this.xhrData.map((val, index) => {
        if (val.id == item.id) {
          this.xhrData.splice(index, 1);
        }
      });
    }
  },
  watch: {
    list (newVal) {
      this.xhrData = newVal||[];
    },
    xhrData (newVal) {
      this.$emit("change", newVal);
    },
    $route () {
      const searchValue = this.$store.getters.getSearchDrugsValue;
      if (searchValue) {
        this.xhrData.push(searchValue);
        this.$store.commit("setSearchDrugsValue", null);
      }
    }
  },
  components: {}
};
</script>

<style scoped>
.form-drugs-box {
  padding-top: 0.16rem;
  border-bottom: solid 0.01rem #f6f8fb;
  line-height: 1;
}
.form-label-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.16rem;
}
.form-label {
  font-size: 0.14rem;
  color: #737885;
}
.form-label-add {
  width: 0.2rem;
  height: 0.2rem;
  background: url('~@images/min_add_icon.png') no-repeat center/0.18rem;
}
.form-drugs-list .form-drugs-item:last-of-type {
  border-bottom: 0;
}
.form-drugs-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  padding: 0.17rem 0;
  border-bottom: solid 0.01rem #f6f8fb;
}
.form-drugs-item p {
  font-size: 0.14rem;
  color: #30353d;
}
.form-drugs-item i {
  width: 0.2rem;
  height: 0.2rem;
  background: url('~@images/del_icon.png') no-repeat center/0.1rem;
}
.form-drugs-item .form-drugs-disable {
  color: #737885;
}
</style>
