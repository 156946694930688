<template>
  <div class="form-tag-box">
    <label class="form-label">{{label}}</label>
    <ul class="form-tag-list" :class="{'form-tag-list-disable':disable}">
      <li v-for="(item,index) in slots" :key="index" class="form-tag-item" :class="{'form-tag-item-check':item.check}" @click="itemEvent(item);">{{item.name}}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "x-form-tag",
  data () {
    return {};
  },
  props: {
    label: {
      type: String,
      default: null
    },
    disable: {
      type: Boolean,
      default: false
    },
    slots: {
      type: Array,
      default: () => []
    },
    tagList: {
      type: Array,
      default: () => []
    }
  },
  created () { },
  methods: {
    itemEvent (item) {
      if (!this.disable) {
        this.$set(item, "check", !item.check);
        const paramsArr = [];
        this.slots.map(item => {
          item.check && paramsArr.push(item);
        });
        this.$emit("change", paramsArr);
      }
    }
  },
  watch: {
    tagList (newVal) {
      newVal.map(val => {
        this.slots.map(item => {
          if (item.code == val) {
            item.check = true;
          }
        });
      });
    }
  },
  components: {}
};
</script>

<style scoped>
.form-tag-box {
  padding-top: 0.16rem;
  border-bottom: solid 0.01rem #f6f8fb;
  line-height: 1;
}
.form-label {
  font-size: 0.14rem;
  color: #737885;
}
.form-tag-list {
  margin-top: 0.14rem;
  display: flex;
  flex-wrap: wrap;
}
.form-tag-item {
  line-height: 1;
  font-size: 0.14rem;
  color: #6e727a;
  padding: 0.1rem 0.12rem;
  background-color: #f1f4f8;
  border-radius: 0.04rem;
  margin: auto 0.1rem 0.1rem 0;
}
.form-tag-list .form-tag-item:nth-child(4n) {
  margin-right: 0;
}
.form-tag-list-disable .form-tag-item {
  color: #afb2b9;
}
.form-tag-item-check {
  color: #2b82f7;
  background-color: #e0ebf8;
}
.form-tag-list-disable .form-tag-item-check {
  color: #73acf7;
}
</style>
