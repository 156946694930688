<template>
  <div class="map-box">
    <div ref="mapContainer" class="map-container"></div>
    <button v-if="!endCenter[0]&&!endCenter[1]" class="address-pos-btn" @click="posEvent"></button>
  </div>
</template>

<script>
import TMap from "TMap";
import wx from "weixin-js-sdk";
import { getJsSdkSignature } from "@/api/base";

export default {
  name: "x-map",
  data () {
    return {
      myCenter: null,
      map: null,
      markerLayer: null,
      circleLayer: null
    };
  },
  props: {
    center: {
      type: Array,
      default: () => []
    },
    endCenter: {
      type: Array,
      default: () => []
    }
  },
  mounted () {
    this.wxConfig();
  },
  methods: {
    wxConfig () {
      getJsSdkSignature({
        url: window.location.href
      }).then(xhr => {
        wx.config({
          beta: true,
          debug: false,
          appId: xhr.data.appId,
          timestamp: xhr.data.timestamp,
          nonceStr: xhr.data.nonceStr,
          signature: xhr.data.signature,
          jsApiList: ["getLocation"]
        });
        this.setPos();
      });
    },
    setPos () {
      // this.myCenter = [39.937998782, 116.330614544];
      // this.initMap();

      if (this.endCenter[0] && this.endCenter[1]) {
        this.myCenter = [this.endCenter[0], this.endCenter[1]];
        this.initMap();
      } else {
        const that = this;
        wx.ready(() => {
          wx.getLocation({
            type: "gcj02",
            success (res) {
              that.myCenter = [res.latitude, res.longitude];
              that.initMap();
            },
            cancel (res) {
              alert("获取位置失败,请打开GPS功能！");
              console.log(res);
            }
          });
        });
      }
    },
    initMap () {
      const myDot = new TMap.LatLng(this.myCenter[0], this.myCenter[1]);
      const customerDot = new TMap.LatLng(this.center[0], this.center[1]);
      if (!this.map) {
        this.map = new TMap.Map(this.$refs["mapContainer"], {
          center: myDot,
          zoom: 15,
          showControl: false
        });
      }
      this.marketEvent(myDot);
      this.circleEvent(customerDot);
      // const distance = TMap.geometry.spherical.computeDistanceBetween(myDot, customerDot);
      const distance = TMap.geometry.computeDistance([myDot, customerDot]);
      this.$emit("change", {
        latLng: this.myCenter,
        scopeIs: distance.toFixed(2) <= 300 ? 1 : 0
      });
    },
    marketEvent (coordinate) {
      if (this.markerLayer) {
        this.markerLayer.updateGeometries([{
          "styleId": "myMarker",
          "id": "2",
          "position": coordinate
        }]);
      } else {
        this.markerLayer = new TMap.MultiMarker({
          map: this.map,
          styles: {
            "marker": new TMap.MarkerStyle({
              "width": 48,
              "height": 64,
              "anchor": { x: 24, y: 64 },
              "src": require("../../assets/images/client_pos_icon.png")
            }),
            "myMarker": new TMap.MarkerStyle({
              "width": 48,
              "height": 64,
              "anchor": { x: 24, y: 64 },
              "src": require("../../assets/images/sale_pos_icon.png")
            })
          },
          geometries: [{
            "id": "1",
            "styleId": "marker",
            "position": new TMap.LatLng(this.center[0], this.center[1])
          }, {
            "id": "2",
            "styleId": "myMarker",
            "position": new TMap.LatLng(this.myCenter[0], this.myCenter[1])
          }]
        });
      }
      this.map.setCenter(coordinate);
    },
    circleEvent (coordinate) {
      if (!this.circleLayer) {
        this.circleLayer = new TMap.MultiCircle({
          map: this.map,
          styles: {
            "circle": new TMap.CircleStyle({
              "color": "rgba(41,91,255,0.16)",
              "showBorder": true,
              "borderColor": "rgba(41,91,255,1)",
              "borderWidth": 1
            })
          },
          geometries: [{
            "styleId": "circle",
            "center": coordinate,
            "radius": 300
          }]
        });
      }
    },
    posEvent () {
      this.setPos();
    }
  },
  components: {}
};
</script>

<style scoped>
.map-box {
  position: relative;
  width: 100%;
  height: 2.1rem;
  margin-bottom: 0.2rem;
}
.map-container {
  position: absolute;
  width: 100%;
  height: 2.1rem;
}
.address-pos-btn {
  position: absolute;
  right: 0.1rem;
  bottom: 0.18rem;
  border: 0;
  z-index: 1001;
  width: 0.38rem;
  height: 0.38rem;
  background: url('~@images/max_pos_icon.png') no-repeat center / 0.38rem;
}
</style>
