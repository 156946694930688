<template>
  <div class="footer-box">
    <button v-if="type=='edit'" class="del-btn" @click="delEvent">删除</button>
    <button v-if="type=='edit'" class="save-btn" :class="{'btn-disabled':isDisabledBtn}" @click="saveEvent">保存</button>
    <button v-if="type=='new'" class="max-save-btn" :class="{'btn-disabled':isDisabledBtn}" @click="saveEvent">保存</button>
    <button v-if="type=='print'" class="print-btn" :class="{'btn-disabled':isDisabledBtn}" @click="printEvent">打印</button>
  </div>
</template>

<script>
import { MessageBox } from "mint-ui";

export default {
  name: "x-footer-box",
  data () {
    return {};
  },
  props: {
    type: {
      type: String,
      default: "new"
    },
    isDisabledBtn: {
      type: Boolean,
      default: true
    }
  },
  created () { },
  methods: {
    saveEvent () {
      this.$emit("saveEvent");
    },
    delEvent () {
      MessageBox({
        title: null,
        message: "确定执行此操作?",
        showCancelButton: true
      }).then(action => {
        action == "confirm" && this.$emit("delEvent");
      });
    },
    printEvent () {
      this.$emit("printEvent");
    }
  },
  components: {}
};
</script>

<style scoped>
.footer-box {
  position: absolute;
  width: 100%;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 0.66rem;
}
.footer-box button {
  font-size: 0.17rem;
  width: 1.4rem;
  height: 0.46rem;
  border-radius: 0.24rem;
  border: 0;
}
.del-btn {
  background-color: rgba(230, 91, 72, 0.12);
  color: #f75959;
}
.save-btn,
.print-btn,
.max-save-btn {
  background-color: #2b82f7;
  color: #ffffff;
}
.footer-box .max-save-btn {
  width: 2.7rem;
}
.btn-disabled {
  background: #9fc7fc;
  box-shadow: 0 0.02rem 0.02rem 0 #d0e8ff;
}
</style>
