import {
  uploadFileApi
} from "@/api/base";
import {
  Toast
} from "mint-ui";
import lrz from "lrz";

export const upLoadFile = {
  methods: {
    upload(event, callBack) {
      const checkImg = new RegExp("(.jpg$)|(.png$)|(.bmp$)|(.jpeg$)", "i");
      if (checkImg.test(event.target.value)) {
        const file = event.target.files[0];
        lrz(file, {
          quality: 0.5
        }).then(rst => {
          const formData = new FormData();
          formData.append("fileName", rst.origin.name);
          formData.append("file", rst.file);
          uploadFileApi(formData).then(xhr => {
            if (xhr.code == 200) {
              Toast("上传成功！");
              callBack(xhr.data);
            }
          });
        });
        // const reader = new FileReader();
        // reader.readAsDataURL(event.target.files[0]);
        // reader.onload = evt => {
        //   console.log(evt.target.result);
        //   const formData = new FormData();
        //   formData.append("fileName", event.target.files[0].name);
        //   formData.append("file", this.convertBase64UrlToBlob(evt.target.result));
        //   uploadFileApi(formData).then(xhr => {
        //     if (xhr.code == 200) {
        //       Toast("上传成功！");
        //       callBack(xhr.data);
        //     }
        //   });
        // };
      } else {
        Toast("请上传.jpg .png .bmp .jpeg的文件格式");
      }
    },
    convertBase64UrlToBlob(urlData) {
      const bytes = atob(urlData.split(",")[1].replace(/\s/g, "")),
        ab = new ArrayBuffer(bytes.length),
        ia = new Uint8Array(ab);
      for (let i = 0; i < bytes.length; i++) {
        ia[i] = bytes.charCodeAt(i);
      }
      return new Blob([ab], {
        type: urlData.split(",")[0].match(/:(.*?);/)[1]
      });
    }
  }
};
