<template>
  <div>
    <ul class="form-images-list">
      <li v-for="(item,index) in xhrData" :key="index" class="form-image-item">
        <img class="form-images" :src="item.downLoadUrl" @click="maxImage(item.downLoadUrl);" />
        <p v-if="!disable" class="form-image-del" @click="delEvent(item.id);">删除</p>
      </li>
      <li v-if="xhrData.length<3&&!disable" class="form-image-item">
        <input type="file" class="form-file" @change="uploadFile($event)" />
      </li>
    </ul>
    <div v-show="show" class="popup-box">
      <div class="popup-img-box">
        <i class="close-icon" @click="show=!show;"></i>
        <img :src="src">
      </div>
    </div>
  </div>
</template>

<script>
import { upLoadFile } from "@/script/mixins/upLoadFile";
import { deleteFile } from "@/api/base";

export default {
  name: "x-form-images",
  data () {
    return {
      src: null,
      show: false,
      isLoad: true,
      xhrData: []
    };
  },
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    imgList: {
      type: Array,
      default: () => []
    }
  },
  mixins: [upLoadFile],
  created () { },
  methods: {
    maxImage (url) {
      this.src = url;
      this.show = true;
    },
    uploadFile (event) {
      this.fileName = event.target.files[0].name;
      this.upload(event, (data) => {
        this.isLoad = false;
        this.xhrData.push(data);
      });
    },
    delEvent (id) {
      deleteFile({
        id: id
      }).then(xhr => {
        if (xhr.code == 200) {
          this.xhrData.map((item, index) => {
            item.id == id && this.xhrData.splice(index, 1);
          });
        }
      });
    }
  },
  watch: {
    imgList (newVal) {
      if (this.isLoad) {
        this.isLoad = false;
        this.xhrData = newVal || [];
      }
    },
    xhrData (newVal) {
      this.$emit("change", newVal);
    }
  },
  components: {}
};
</script>

<style scoped>
.close-icon {
  float: right;
  margin-bottom: 0.15rem;
  width: 0.26rem;
  height: 0.26rem;
  background: url('~@images/close_icon.png') no-repeat center/0.26rem;
}
.popup-box {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup-img-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.popup-img-box img {
  max-width: 3.45rem;
  max-height: 4.64rem;
  object-fit: contain;
}
.form-images-list {
  margin-top: 0.2rem;
  display: flex;
}
.form-image-item {
  position: relative;
  width: 1.08rem;
  height: 1.08rem;
  background: url('~@images/add_image_icon.png') no-repeat center/1.08rem;
  margin-right: 0.1rem;
}
.form-images-list .form-image-item:last-of-type {
  margin-right: 0;
}
.form-images {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.form-file {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.form-image-del {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
  padding: 0.03rem 0;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 0.12rem;
  color: #ffffff;
  line-height: 1;
}
</style>
