<template>
  <div class="form-pos-box">
    <label class="form-label">{{label}}</label>
    <div class="form-info-box">
      <p class="form-info-address" :class="{'form-info-address-disable':disable,'form-info-address-red':!isRed}">{{address}}</p>
      <p v-if="dates" class="form-info-time">{{dates}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "x-form-position",
  data () {
    return {
    };
  },
  props: {
    label: {
      type: String,
      default: null
    },
    isRed: {
      type: [Number, String],
      default: null
    },
    disable: {
      type: Boolean,
      default: false
    },
    address: {
      type: String,
      default: null
    },
    dates: {
      type: String,
      default: null
    }
  },
  created () { },
  methods: {},
  components: {}
};
</script>

<style scoped>
.form-pos-box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0.16rem 0;
  border-bottom: solid 0.01rem #f6f8fb;
  line-height: 1;
}
.form-label {
  font-size: 0.14rem;
  color: #737885;
}
.form-info-box {
  display: flex;
  flex-wrap: wrap;
  text-align: right;
  width: 2.7rem;
}
.form-info-box p {
  width: 100%;
}
.form-info-address {
  line-height: 0.16rem;
  font-size: 0.14rem;
  color: #737885;
}
.form-info-address-disable {
  color: #30353d;
}
.form-info-address-red {
  color: #f75959;
}
.form-info-time {
  margin-top: 0.1rem;
  font-size: 0.13rem;
  color: #afb2b9;
}
</style>
