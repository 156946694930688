<template>
  <div class="form-area-box">
    <label class="form-label">{{label}}</label>
    <textarea class="form-text-area" rows="3" :disabled="disable" placeholder="请输入具体描述" v-model="newValue" maxlength="200"></textarea>
    <p v-if="!disable" class="num-count">{{countNum}}/200</p>
  </div>
</template>

<script>
export default {
  name: "x-form-textarea",
  data () {
    return {
      countNum: 0
    };
  },
  props: {
    label: {
      type: String,
      default: null
    },
    disable: {
      type: Boolean,
      default: false
    },
    value: {
      type: [Number, String],
      default: null
    }
  },
  created () { },
  methods: {},
  computed: {
    newValue: {
      get () {
        return this.value;
      },
      set (value) {
        this.$emit("input", value);
      }
    }
  },
  watch: {
    value (newVal) {
      this.countNum = newVal.length;
    }
  },
  components: {}
};
</script>

<style scoped>
.form-area-box {
  padding-top: 0.16rem;
  border-bottom: solid 0.01rem #f6f8fb;
  line-height: 1;
}
.form-label {
  font-size: 0.14rem;
  color: #737885;
}
.form-text-area {
  border: 0;
  width: 100%;
  margin-top: 0.17rem;
  font-size: 0.14rem;
}
.form-text-area::-webkit-input-placeholder {
  color: #d0d3d8;
}
.num-count {
  line-height: 1;
  margin-bottom: 0.03rem;
  text-align: right;
  font-size: 0.12rem;
  color: #d0d3d8;
}
</style>
