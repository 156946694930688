import {
  MessageBox
} from "mint-ui";

export const ajaxBack = {
  methods: {
    ajaxBack(xhr, msg, goNum) {
      if (xhr.code == 200) {
        MessageBox({
          title: null,
          message: msg
        }).then(action => {
          action == "confirm" && goNum ? this.$router.go(goNum) : this.$router.back();
        });
      }
    }
  }
};
